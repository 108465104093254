import { integrationsPanelUrl } from '@/services/settings';

export default {
  name: 'IntegrationPanel',
  components: {},
  data() {
    return {
      selectedTab: 'general',
      iframeUrl: ''
    };
  },
  watch: {
    '$route.params.section1': function () {
      this.buildIframeUrl();
    },
    '$route.params.section2': function () {
      this.buildIframeUrl();
    }
  },
  methods: {
    onTabChange(newTab) {
      this.selectedTab = newTab;
    },
    buildIframeUrl() {
      let section1 = this.$route.params.section1;
      let section2 = this.$route.params.section2;

      let url = integrationsPanelUrl;
      if (section1) {
        url += '/' + section1;
      }
      if (section2) {
        url += '/' + section2;
      }

      // Read Querystrign params as well
      if (location.search) {
        url += location.search;
      }

      this.iframeUrl = url;
    }
  },

  mounted() {
    document.getElementById('subSideMenu').style.display = 'none';
    this.buildIframeUrl();
  },

  beforeDestroy() {
    document.getElementById('subSideMenu').style.display = 'block';
  }
};
