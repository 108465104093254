<template>
  <div id="integrationPanelPage">
    <iframe v-if="iframeUrl" :src="iframeUrl" samesite="none" secure="true"></iframe>
  </div>
</template>

<script>
import integrationsPanelComponent from './integrationsPanelComponent';
export default integrationsPanelComponent;
</script>

<style lang="scss" src="./integrationsPanel.scss"></style>
